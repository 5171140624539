import { ConsultantContractState } from "@/gql"

export const useConsultantContractState = (state: ConsultantContractState): { state: string } => {
  const stateMap: Record<ConsultantContractState, string> = {
    developing: "developing",
    approving: "finance approving",
    approved: "pending acceptance",
    rejected: "finance rejected",
    accepted: "accepted",
    declined: "declined",
    terminating: "terminating",
    terminated: "terminated",
    signed: "signed",
  }

  return { state: stateMap[state] }
}
