
import { ConsultantContractFragmentFragment, EsignatureFragmentFragment } from "@/gql"
import { Vue, Component, Prop } from "vue-property-decorator"
import { ContractingEntities } from "@/constants"
import moment from "moment"
import Signature from "@/components/Esignature/Signature/Signature.vue"

@Component({
  components: {
    Signature,
  },
})
export default class ConsultingAgreementTemplate extends Vue {
  @Prop() readonly contract!: ConsultantContractFragmentFragment

  eSignature: EsignatureFragmentFragment | null = null
  get signatureSource() {
    return this.eSignature?.signatureSource
  }
  get consultant() {
    return this.contract.consultant
  }
  get signable() {
    return {
      id: this.contract.id,
      type: this.contract.__typename,
    }
  }
  get contractingEntityAddress() {
    return (
      ContractingEntities.find((entity) => entity.value === this.contract.contractingEntity) || {}
    ).address
  }
  get missionLifecycle() {
    return this.contract.missionLifecycle
  }
  get duration() {
    const duration = moment(this.contract.conclusionDate).diff(
      this.contract.commencementDate,
      "weeks"
    )
    return duration === 0 ? "1-week" : `${duration}-weeks`
  }
  get payments() {
    return this.contract!.application!.missionStaffingPosition.consultantContractPayments
  }

  mounted() {
    if (this.contract.signatures.length) {
      this.eSignature = this.contract.signatures[0]
    }
  }
}
