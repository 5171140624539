
import { Vue, Prop, Component, Watch, Emit } from "vue-property-decorator"
import {
  ConsultantContractMilestoneInput,
  ConsultantContractMilestone,
  UpdateConsultantContractMilestoneMutation,
} from "@/gql"

@Component
export default class EditConsultantContractMilestoneModal extends Vue {
  @Prop({ required: true })
  readonly milestone!: ConsultantContractMilestone

  defaultForm: {
    description: string
    fees: number | null
  } = {
    description: "",
    fees: null,
  }

  form = { ...this.defaultForm }

  resetForm() {
    this.$set(this, "form", { ...this.defaultForm })
    this.$refs.observer && (this.$refs.observer as any).reset()
  }

  @Emit("close")
  @Emit("input")
  onClose() {
    this.resetForm()
    return false
  }

  async onSave() {
    const { description, fees } = this.form,
      feesCents = fees! * 100,
      payload: ConsultantContractMilestoneInput = {
        description,
        feesCents,
      }
    const result = await this.mutate({
      mutation: UpdateConsultantContractMilestoneMutation,
      variables: {
        consultantContractMilestoneId: this.milestone.id || undefined,
        consultantContractMilestone: { ...payload },
      },
    })

    if (result.data?.updateConsultantContractMilestone.consultantContract) {
      this.saveComplete()
    }
  }

  @Emit("save")
  saveComplete() {
    this.resetForm()
  }

  @Watch("milestone")
  populateForm() {
    if (this.milestone) {
      this.form.description = this.milestone.description
      this.form.fees = this.milestone.fees
    } else {
      this.resetForm()
    }
  }
}
