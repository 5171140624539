
import { Vue, Prop, Component } from "vue-property-decorator"

@Component
export default class UserCard extends Vue {
  @Prop({ required: true }) title!: string
  @Prop({ required: true }) subtitle!: string
  @Prop({ required: false, default: "" }) avatar!: string
  @Prop({ required: false, default: false }) active!: boolean
  @Prop({ default: true }) readonly outlined!: boolean

  @Prop({ required: true })
  to!: { [key: string]: any }
}
